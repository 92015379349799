body {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  background-color: #ECF0F5;
  font-size: 13px;
  color: #444444;
  text-align: center;
  line-height: 1.5;
  /*background-image: url('../../images/bottles.jpg');*/
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p, label, button, input {
  font-size: 16px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #BBBBBB;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  /*background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;*/
}

li {
  font-size: 13px;
}

section {
  background-color: white;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
}

button, input[type=submit] {
  background-color: #4CAF50;
  width: 150px;
  height: 40px;
  border-radius: 4px;
  margin: 10px;
  font-size: 15px;
}

.App-link {
  color: #09d3ac;
}


img {
  max-width: 90%;
}

.red {
  color: red;
}

.orange {
  color: orange;
}

.green {
  color: green;
}

.pet-desc {
  display: table-cell;
  height: auto;
  border: 10px solid #BBBBBB;
  width: 40%;
  margin: 10px;
  padding: 10px;
}
